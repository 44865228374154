import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import vertexShader from './shaders/background/vertex.glsl'
import fragmentShader from './shaders/background/fragment.glsl'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import * as TWEEN from '@tweenjs/tween.js';
import gsap from 'gsap';
import SplitTextJS from 'split-text-js'; // Adjusted import path
const texture1 = new THREE.TextureLoader().load('/textures/wood.jpg'); 
/**
 * Base
 */
// Debug
// const gui = new GUI({ width: 340 })
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
const scene1 =  new THREE.Scene()

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
window.addEventListener('resize', () => {
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})
/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(47, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0.00035, 0.00027, 0.001) // Adjusted camera position to view the scene
scene.add(camera)
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.minPolarAngle = Math.PI * 0.25; // Minimum vertical angle in radians (from the bottom)
controls.maxPolarAngle = Math.PI * 0.75; // Maximum vertical angle in radians (from the top)
controls.minAzimuthAngle = -Math.PI * 0.12; // Minimum horizontal angle in radians (clockwise from north)
controls.maxAzimuthAngle = Math.PI * 0.12; // Maximum horizontal angle in radians 
const texture = new THREE.TextureLoader().load('./textures/wood.jpg'); 
let initialCameraY = 3;
let floatingSpeed = 2;
let floatingAmplitude = 0.5;
let mixer = null;
// Loaders
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)
// Load the GLTF model
// Define separate mixer variables for each model
let mixer1 = null;
let model = null; // Declare the model variable outside the gltfLoader.load function
gltfLoader.load(
    './models/tenhun_falling_spaceman_fanart/scene.gltf',
    (gltf) => {
        
        // Add the loaded scene to the scene graph
        model = gltf.scene; // Assign the model to the variable declared outside
        model.position.set(0, -0.2, -0.5);
        model.rotation.set(1, 1.5, -2) // Set the position of the model
        scene.add(model);
        const scaleFactor = 0.10; // Adjust as needed to scale down the model
        model.scale.set(scaleFactor, scaleFactor, scaleFactor); // Scale down the model
        
        // Create a mixer for this model
        mixer1 = new THREE.AnimationMixer(model);
        const clips = gltf.animations;
        clips.forEach(function(clip){
            const action = mixer1.clipAction(clip);
            action.play();
        });
    },
    () => {
        console.log('Loading progress...');
    },
    (error) => {
        console.error('Error loading:', error);
    }
);

//other models
// Canvas
const canvas2 = document.querySelector('.webgl1');
canvas2.height = 600;
canvas2.width  = 400;

let mixer3 = null;
let mixer2 = null;

gltfLoader.load(
    './models/scene.gltf',
    (gltf) => {

        const model = gltf.scene; 
        model.position.set(0, 0, 0); 
        scene1.add(model);
        console.log("Model Information:\n" +
    "* title: Smol Ame in an Upcycled Terrarium [HololiveEn]\n" +
    "* source: https://sketchfab.com/3d-models/smol-ame-in-an-upcycled-terrarium-hololiveen-490cecc249d242188fda5ad3160a4b24\n" +
    "* author: Seafoam (https://sketchfab.com/seafoam)\n\n" +
    "Model License:\n" +
    "* license type: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)\n" +
    "* requirements: Author must be credited. Commercial use is allowed.\n\n" +
    "If you use this 3D model in your project be sure to copy paste this credit wherever you share it:\n" +
    "This work is based on \"Smol Ame in an Upcycled Terrarium [HololiveEn]\" (https://sketchfab.com/3d-models/smol-ame-in-an-upcycled-terrarium-hololiveen-490cecc249d242188fda5ad3160a4b24) by Seafoam (https://sketchfab.com/seafoam) licensed under CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)");

        // Create a mixer for this model
        mixer3 = new THREE.AnimationMixer(model);
        const clips = gltf.animations;
        clips.forEach(function(clip){
            const action = mixer3.clipAction(clip);
            action.play();
        });
    },
    () => {
        console.log('Loading progress..');
    },
    (error) => {
        console.error('Error loading :', error);
    }
);

console.log('Music by <a href="https://pixabay.com/users/juliush-3921568/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=12551">Julius H.</a> from <a href="https://pixabay.com/music//?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=12551">Pixabay</a>');

gltfLoader.load(
    './models/firefly-minecraft/model.gltf',
    (gltf) => {
        console.log('Loaded:', gltf);

        // Add the loaded scene to the scene graph
        const model = gltf.scene; // Store the model reference
        model.position.set(2, 2, 0); // Set the position of the second model
        scene1.add(model);
        model.rotation.set(0,-1,0)


        // Create a mixer for this model
        mixer2 = new THREE.AnimationMixer(model);
        const clips = gltf.animations;
        clips.forEach(function(clip){
            const action = mixer2.clipAction(clip);
            action.play();
        });
    },
    () => {
        console.log('Loading progress...');
    },
    (error) => {
        console.error('Error loading:', error);
    }
);



// Lights
const ambientLight1 = new THREE.AmbientLight(0xffffff, 2.4)
scene1.add(ambientLight1)

const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1.8)
directionalLight1.castShadow = true
directionalLight1.shadow.mapSize.set(1024, 1024)
directionalLight1.shadow.camera.far = 15
directionalLight1.shadow.camera.left = - 7
directionalLight1.shadow.camera.top = 7
directionalLight1.shadow.camera.right = 7
directionalLight1.shadow.camera.bottom = - 7
directionalLight1.position.set(5, 5, 5)
scene1.add(directionalLight1)

// Camera
const initialFOV = 27;
const camera1 = new THREE.PerspectiveCamera(initialFOV, window.innerWidth / window.innerHeight, 0.1, 100)
camera1.position.set(1, 2, 4)


scene1.add(camera1)

// Controls
const controls1 = new OrbitControls(camera1, canvas2)
controls1.target.set(0, 0.75, 0)
controls1.enableDamping = true
controls1.minDistance = 2; // Minimum distance the camera can zoom in
controls1.maxDistance = 10; // Maximum distance the camera can zoom out
controls1.minPolarAngle = Math.PI / 6; // Minimum angle the camera can move down
controls1.maxPolarAngle = Math.PI / 2; 

scene1.background = new THREE.Color('#5294E2'); // Black background

// Renderer
const renderer1 = new THREE.WebGLRenderer({
    canvas: canvas2
})
renderer1.shadowMap.enabled = true
renderer1.shadowMap.type = THREE.PCFSoftShadowMap
renderer1.setSize(canvas2.height, canvas2.width)
renderer1.setPixelRatio(Math.min(window.devicePixelRatio, 2))



// Floor
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(10, 10),
    new THREE.MeshStandardMaterial({
       map: texture
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
floor.position.y = -0.1
scene1.add(floor)

// Lights
const ambientLight = new THREE.AmbientLight(0xffffff, 2.4)
scene.add(ambientLight)
const directionalLight = new THREE.DirectionalLight(0xffffff, 1.8)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(5, 5, 5)
scene.add(directionalLight)
/**
 * Water
 */
const waterGeometry = new THREE.IcosahedronGeometry(1, 50)
const waterMaterial = new THREE.ShaderMaterial({
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    side: THREE.DoubleSide,
    uniforms: {
        uTime: { value: 0 }
    }
})
const water = new THREE.Mesh(waterGeometry, waterMaterial)
water.rotation.x = -Math.PI * 0.5
scene.add(water)
document.addEventListener('DOMContentLoaded', function() {
    const magnetoButtons = document.querySelectorAll('.magneto');
    magnetoButtons.forEach((magneto) => {
        const magnetoText = magneto.querySelector('.text');
        const activateMagneto = (event) => {
            const boundBox = magneto.getBoundingClientRect();
            const magnetoStrength = 40;
            const magnetoTextStrength = 80;
            const newX = ((event.clientX - boundBox.left) / magneto.offsetWidth) - 0.5;
            const newY = ((event.clientY - boundBox.top) / magneto.offsetHeight) - 0.5;
            gsap.to(magneto, {
                duration: 1,
                x: newX * magnetoStrength,
                y: newY * magnetoStrength,
                ease: Power4.easeOut
            });
            gsap.to(magnetoText, {
                duration: 1,
                x: newX * magnetoTextStrength,
                y: newY * magnetoTextStrength,
                ease: Power4.easeOut
            });
        };
        const resetMagneto = () => {
            gsap.to(magneto, {
                duration: 1,
                x: 0,
                y: 0,
                ease: Elastic.easeOut
            });
            gsap.to(magnetoText, {
                duration: 1,
                x: 0,
                y: 0,
                ease: Elastic.easeOut
            });
        };
        magneto.addEventListener('mousemove', activateMagneto);
        magneto.addEventListener('mouseleave', resetMagneto);
    });
});
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({ canvas: canvas })
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// Update loop
const update = () => {
    TWEEN.update(); // Update Tween.js
    // Other update logic...
    renderer.render(scene, camera);
    requestAnimationFrame(update);
    
};
// Start the update loop
update();
/**
 * Animate
 */
const clock = new THREE.Clock()
const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    waterMaterial.uniforms.uTime.value = elapsedTime
    controls.update()
    // Update the mixer for the model animation
    if (mixer1) {
        mixer1.update(elapsedTime * 0.001); // Update the mixer with the elapsed time
    }
    if (mixer3) {
        const deltaTime = clock.getDelta();
        mixer3.update(deltaTime * 100);
    }
    if (mixer2) {
        const deltaTime = clock.getDelta();
        mixer2.update(deltaTime * 30);
    }
    controls1.update()
    // Rotate the model
    const rotationSpeed = 0.005 ;
    if (model) {
        model.rotation.y += rotationSpeed;
        // model.rotation.x += rotationSpeed;
    }
    // Move the model from top to bottom
    const movementSpeed = 0.0001;
    if (model) {
        // model.position.y -= movementSpeed;
        model.position.x -= movementSpeed;
        model.position.z += movementSpeed;
        // If the model goes below a certain y position, reset its position to the top
        if (model.position.y < -5) {
            model.position.y = 5;
        }
    }

    renderer.render(scene, camera)
    renderer1.render(scene1, camera1)

    window.requestAnimationFrame(tick)
}
tick()
const titles = gsap.utils.toArray('p');
const tl = gsap.timeline({repeat: -1,repeatDelay: 0.2});
for (let i = 0; i < titles.length; i++) {
    const splitTitle = new SplitTextJS(titles[i]); // Pass the current <p> element to SplitTextJS
    tl.from(splitTitle.chars, {
        opacity: 0,
        y: 80,
        rotateX: -90,
        ease: "power3.out",
        delay:  0.5
    }, "<")
    .to(splitTitle.chars, {
        opacity: 0,
        y: -80,
        rotateX: 90,
        ease: "power3.in"
    }, "<1");
}
const scrollers = document.querySelectorAll(".scroller");
// If a user hasn't opted in for recuded motion, then we add the animation
if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
  addAnimation();
}
function addAnimation() {
  scrollers.forEach((scroller) => {
    // add data-animated="true" to every `.scroller` on the page
    scroller.setAttribute("data-animated", true);
    // Make an array from the elements within `.scroller-inner`
    const scrollerInner = scroller.querySelector(".scroll-inner");
    const scrollerContent = Array.from(scrollerInner.children);
    // For each item in the array, clone it
    // add aria-hidden to it
    // add it into the `.scroller-inner`
    scrollerContent.forEach((item) => {
      const duplicatedItem = item.cloneNode(true);
      duplicatedItem.setAttribute("aria-hidden", true);
      scrollerInner.appendChild(duplicatedItem);
    });
  });
}
